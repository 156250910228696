$dark: #121212;
$light: #fafafa;
$Exotic: #ceae33;
$Legendary: #522f65;
$Rare: #5076a3;
$Uncommon: #366f42;
$Common: #c3bcb4;
$ExoticBright: saturate(
  $color: lighten($Exotic, 10%),
  $amount: 20%,
);

$cutout-amount: 150px;
$button-highlight: $ExoticBright;

@mixin bg-base {
  image-rendering: pixelated;
  background-position-x: 70%;
  background-position-y: 15%;

  @media (-webkit-min-device-pixel-ratio: 2) {
    image-rendering: unset;
  }

  @media screen and (max-width: 760px) {
    background-position: center center;
  }
}

html,
body {
  background: $dark;
  color: $light;
  font-family: neue-haas-grotesk-text, sans-serif;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

html {
  font-size: 32px;
}

* {
  box-sizing: border-box;
}

.nhg-only {
  display: none;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.5;
}

.big {
  font-family: neue-haas-grotesk-display, "Comic Sans MS", sans-serif !important;
  font-size: 3rem;
  font-weight: 900;
  line-height: 1.1;
}

.hero {
  position: relative;

  h1 {
    margin-top: 0;
  }
}

.hero-overlay {
  width: calc(50% + #{$cutout-amount});
  min-height: 100vh;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: rgba($dark, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  clip-path: polygon(0 0, 100% 0, calc(100% - #{$cutout-amount}) 100%, 0% 100%);
}

.key {
  // display: inline-block;
  font-size: 0.75em;
  padding: 0 0.2em;
  position: relative;
  top: -2px;
  vertical-align: middle;
  border-radius: 6px;
  border: 2px solid $light;
}

.hero-content {
  max-width: 700px;
  margin: 40px;
  margin-right: $cutout-amount;
}

.app-name {
  font-size: 1.375rem; // 44px;
}

.logo {
  height: 1.375rem;
}

.sub {
  font-size: 0.7rem;

  > * {
    opacity: 0.7;
  }
}

.download-button {
  color: $dark;
  background: $light;
  text-decoration: none;
  padding: 16px 20px;
  display: inline-block;
  border-radius: 16px;
  font-weight: 500;
  font-size: 0.7rem;

  &:hover {
    box-shadow: 0 0 0 3px $button-highlight, 0 0 0 0 rgba($button-highlight, 0);
  }
}

section {
  // text-align: center;
  margin: 100px 0;
}

.container {
  max-width: 900px;
  padding: 0 32px;
  margin: auto;
}

.heading {
  font-size: 1.3rem;
  color: $ExoticBright;

  // &:before {
  //   content: " ";
  //   display: block;
  //   height: 4px;
  //   width: 50px;
  //   background: saturate($color: lighten($Exotic, 10%), $amount: 20%);
  //   margin-bottom: 8px;
  // }
}

.link {
  color: inherit;
  transition: 50ms ease-in-out;

  &:hover {
    color: $ExoticBright;

    .sub & {
      opacity: 1;
    }
  }
}

.screenshot {
  max-width: 100vw;
  padding: 0;
  margin: auto;
  display: block;
}

.answer + .question {
  margin-top: 1.5em;
}

.answer {
  font-size: 0.75em;
  line-height: 1.4;
  margin-top: -20px;
}

.privacy {
  font-size: 24px;

  h2 {
    font-size: 1.3em;
    margin-bottom: 0;
    margin-top: 1.5em;
  }

  p {
    margin: 0.75em 0;
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 20px;
  }

  .hero-overlay {
    min-height: 75vh;
    width: calc(40% + #{$cutout-amount});
  }
}

@media screen and (max-width: 760px) {
  html {
    font-size: 20px;
  }

  .hero-overlay {
    min-height: initial;
    width: 100%;
    clip-path: initial;
    margin: 0;
  }

  .hero-content {
    margin: 32px;
  }
}

.bg-crucible-1 {
  @include bg-base;
  background-image: url("./images/bg-crucible-1.jpg");
}

.bg-crucible-2 {
  @include bg-base;
  background-image: url("./images/bg-crucible-2.jpg");
}

.bg-crucible-3 {
  @include bg-base;
  background-image: url("./images/bg-crucible-3.jpg");
}

.bg-gambit-1 {
  @include bg-base;
  background-image: url("./images/bg-gambit-1.jpg");
}

.bg-gambit-2 {
  @include bg-base;
  background-image: url("./images/bg-gambit-2.jpg");
}
